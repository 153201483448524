import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import useResult from "../hooks/useResult";
import useTrends from "../hooks/useTrends";
import {getUrlParam} from "../constants/helpers";
import {CLIENT_HOST} from "../constants/constants";
import {ThemeContext} from "./ThemeContext";
import ReactGA from "react-ga";
import {getCurrentLocale} from "../constants/locale";

export const SearchContext = createContext({});
export const SearchContextProvider = ({ children, props }) => {
  
  const [query, setQuery] = useState( '')
  const [debounceQuery, setDebounceQuery] = useState('');
  
  const [personalityList, setPersonalityList] = useState([
    {name: 'ContextWriter.AI', photo:'https://asktheai.s3.amazonaws.com/AI+Lawyer.jpg'}
  ].sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }));
  const [personality, setPersonality] = useState(personalityList[0]); // forced to ContextWriter.AI
  
  const [isNative, setIsNative] = useState( false);
  const [page, setPage] = useState( 0);
  const [rowsPerPage, setRowsPerPage] = useState( 10);
  const [type, setType] = useState( '');
  const [locale, setLocale] = useState('en')
  const [filterExplicit, setFilterExplicit] = useState( true);
  const [timeRange, setTimeRange] = useState( 'any')
  const oneMonthAgo = useMemo(()=>{
       let d = new Date();
       d.setMonth(d.getMonth() - 1);
       return d.toISOString().substring(0,10);
  }
  ,[]);
  const [timeRangeCustomStart, setTimeRangeCustomStart] = useState(oneMonthAgo)
  const [timeRangeCustomEnd, setTimeRangeCustomEnd] = useState(new Date().toISOString().substring(0,10))
  const [indexCount, setIndexCount] = useState(0)
  const [entityCount, setEntityCount] = useState(0)
  const [trendingList, setTrendingList] = useState([])
  const [searchResults, setSearchResults] = useState()
  const [totalSearchResults, setTotalSearchResults] = useState(-1)
  const [showExtendedCategories, setShowExtendedCategories] = useState( false)
  const originalPageTitle = useMemo(()=>document.title,[])
  const { darkMode } = useContext(ThemeContext);
  const currentLocale = useMemo(()=>getCurrentLocale(),[])
  const directLink = useMemo(()=>{
    if(timeRange==='custom'){
      return `${CLIENT_HOST}/?native=${isNative}&t=${darkMode ? 'dark' : 'light'}`
    }else{
      return `${CLIENT_HOST}/?native=${isNative}&t=${darkMode ? 'dark' : 'light'}`
    }
     },[isNative, debounceQuery, darkMode])
  
  //onload, over-ride defaults
  
  useEffect(() => {
    if (getUrlParam('native')) {
      setIsNative(true)
    }
  },[]);
  useEffect(() => {
    if (!window.location.hostname.includes("localhost")) { /* Production  Only */
      const adjustedURL = directLink.split(/\.ai\/?|\.dev\/?/);
      ReactGA.initialize('UA-154880-16');
      ReactGA.pageview(adjustedURL[1]);
    }
  },[directLink])
  useEffect(() => {
    if(getUrlParam('q')){
      setQuery(getUrlParam('q'))
    }
    if(getUrlParam('s')){
      setRowsPerPage(Number(getUrlParam('s')))
    }
    if(getUrlParam('p')){
      setPage(Number(getUrlParam('p')))
    }
    if(getUrlParam('c')){
      setType(getUrlParam('c'))
    }
    if(getUrlParam('l')){
      setLocale(getUrlParam('l'))
    }
    if(getUrlParam('m')){
      setFilterExplicit(Boolean(getUrlParam('m')))
    }
    if(getUrlParam('r')){
      setTimeRange(getUrlParam('r'))
    }
    if(getUrlParam('x') && getUrlParam('x') === '1'){
      setShowExtendedCategories(true)
    }
    if(getUrlParam('b')){
      setTimeRangeCustomStart(getUrlParam('b'))
    }
    if(getUrlParam('e')){
      setTimeRangeCustomEnd(getUrlParam('e'))
    }
    
  }, [setQuery, setRowsPerPage,setPage,setType,setLocale,setFilterExplicit,setTimeRange,setShowExtendedCategories,setTimeRangeCustomStart,setTimeRangeCustomEnd]);
  
  
  
  useEffect(() => {
      //setQuery('');
  }, [personality]);
  
  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setDebounceQuery(query);
       // document.title = personality ?  personality.name + " - ContextWriter.ai" : originalPageTitle
    }, 1000);
    
    return () => {
      clearTimeout(timeOutId);
    };
  }, [query, originalPageTitle, personality]);
  
  const trendData = []
  const trendsLoading = false
  //const { data: trendData, isLoading: trendsLoading } = useTrends();
  const { data: searchResultsObj, isLoading: resultsLoading } = useResult(debounceQuery, personality, page, rowsPerPage, type, locale, filterExplicit, timeRange);
  
  useEffect(() => {
   if(searchResultsObj){
     setSearchResults(searchResultsObj.response.choices)
     setTotalSearchResults(Number(searchResultsObj.total_results))
   }
  }, [searchResultsObj, resultsLoading]);
  
  useEffect(() => {
   if(trendData){
     setIndexCount(trendData.indexCount)
     setEntityCount(trendData.entityCount)
     setTrendingList(trendData.data)
   }
  }, [trendData]);
  
  const forceSearch = useCallback(value => {
    setDebounceQuery(query);
  },[query]);
  
  return (
    <SearchContext.Provider
      value={{
        actions: {
          setQuery,
          setTimeRange,
          setLocale,
          setFilterExplicit,
          forceSearch,
          setTrendingList,
          setPage,
          setType,
          setRowsPerPage,
          setTimeRangeCustomStart,
          setTimeRangeCustomEnd,
          setShowExtendedCategories,
          setPersonalityList,
          setPersonality
        },
        state: {
          personality,
          personalityList,
          query,
          debounceQuery,
          timeRange,
          locale,
          filterExplicit,
          timeRangeCustomStart,
          timeRangeCustomEnd,
          trendingList,
          searchResults,
          totalSearchResults,
          resultsLoading,
          trendsLoading,
          indexCount,
          entityCount,
          page,
          type,
          rowsPerPage,
          directLink,
          showExtendedCategories,
          currentLocale
        },
      }}
      {...props}
    >
      {children}
    </SearchContext.Provider>
  );
};
