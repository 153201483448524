import React, {useState, useEffect, useMemo, useRef, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Select, MenuItem } from '@material-ui/core/';

import {
  Box,
  LinearProgress,
  Typography, useMediaQuery, useTheme,
} from '@material-ui/core';
import MarkdownEditor from "@uiw/react-markdown-editor";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import useAI from "../../lib/hooks/useAI";
import {toUnderScoreSlug} from "../../lib/constants/helpers";
import {SearchContext, ThemeContext} from "../../lib/contexts";


const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    /* width: 'fit-content',*/
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientListSmall:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:180,
    height:44,
    margin:'20px auto 0px',
    fontSize:13,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    '& *':{
      color: theme.palette.primary.contrastText,
      display:'inline',
    }
  },
  addPatientListText:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    display:'block',
    margin:'40px auto 0px !important',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    marginTop:-25,
    marginRight:25,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  shareSwitch:{
    color: theme.palette.primary.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  previewButton:{
    color: theme.palette.secondary.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
  container:{
    maxHeight:250,
    overflowY: 'auto',
    margin:'20px auto 20px',
    textAlign:'center',
    '& *':{
      textAlign:'center',
    }
  },
  dynamicVarListItem:{
    marginBottom:20
  }
}));

const AIWriter = ({promptMode = 'AI-Context-Writing'}, isNative = false) => {
  const { darkMode } = useContext(ThemeContext);
  const classes = useStyles();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  const [saving, setSaving] = useState(false);
  const [prompt, setPrompt] = useState(promptMode);
  const [debounceQuery, setDebounceQuery] = useState('');
  const [input, setInput] = useState();
  
  const [contextSummary, setContextSummary] = useState();
  const [autocompleteSummary, setAutocompleteSummary] = useState();
  const [grammerSummary, setGrammerSummary] = useState();
  
  const {isLoading: isLoadingAI, isRefetching: isRefetchingAI, data: ai, refetch: refetchAI} = useAI('writer_completion', toUnderScoreSlug(prompt), debounceQuery);
  const { prompt:previousPrompt, response } = useMemo(()=>ai ?? [], [ai]);
  const textareaRef = useRef(null);
  const timeoutIdRef = useRef(null);
  
  useEffect(() => {
    /*
    if (response && response.choices && response.choices.length && response.choices[0] && response.choices[0].text) {
      let newMarkdown = response.choices[0].text;
      if (newMarkdown !== undefined) {
        setMarkdown(markdown + newMarkdown);
      }
    }*/
    if (response) {
      if (response.context && response.context.choices.length && response.context.choices[0] && response.context.choices[0].text) {
        setContextSummary(response.context.choices[0].text)
      }
      if (response.autocomplete && response.autocomplete.choices.length && response.autocomplete.choices[0] && response.autocomplete.choices[0].text) {
        setAutocompleteSummary(response.autocomplete.choices[0].text)
      }
      if (response.grammer && response.grammer.choices.length && response.grammer.choices[0] && response.grammer.choices[0].text) {
        setGrammerSummary(response.grammer.choices[0].text)
      }
    }
    }, [response]);
  
  
  useEffect(() => {
    if (input) { // && input.slice(-2) === "::" || input && input.slice(-2) === "//"
      timeoutIdRef.current = setTimeout(() => {
        setDebounceQuery(input);
      }, 2000);
      return () => {
        clearTimeout(timeoutIdRef.current);
      };
    }
  }, [input]);
  
  const {
    state: { currentLocale },
  } = useContext(SearchContext);
  
  return (
    <Grid container item xs={12} spacing={0} style={{position:"relative",left: matchSM ? 0 : 8}}>
                  <Grid item xs={12}>
                       <Select
                          fullWidth
                          aria-label="select from personalities"
                          value={prompt}
                          style={{marginBottom:40, display:'none'}}
                       >
                         {['AI-Context-Writing', 'General Auto-Complete', 'Keyword Extraction', 'Legal Agreement', 'Provide Outline'].map((item,index)=> <MenuItem key={index} value={item}
                                                                       onClick={(e)=>setPrompt(item)}>
                           <span>{item}</span>
                         </MenuItem>
                         )}
                       </Select>
                  </Grid>
                  <Grid item xs={12}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={matchSM ? 0 : 2}
                        style={{margin: isNative ? 0 : 'inherit'}}
                  >
                  <Grid item xs={matchSM ? 12 : isNative ? 8 : 7}>
                    <Typography style={{marginTop:matchSM ? 0 : 0, marginBottom:matchSM ? 20 : 0}}>{currentLocale.editor ?? 'Editor'}</Typography>
                    <div data-color-mode={darkMode ? "dark" : "light"}>
                    <MDEditor
                       ref={textareaRef}
                       visible={true}
                       height={matchSM ? 300 : isNative ? '83vh' : 838}
                       width={'100%'}
                       value={input}
                       onChange={(value, viewUpdate) => setInput(value)}
                       warpperElement={{"data-color-mode": darkMode ? "dark" : "light"}}
                       theme={darkMode ? "dark" : "light"}
                       previewOptions={{
                         rehypePlugins: [[rehypeSanitize]],
                       }}
                    />
                    </div>
                  </Grid>
                  <Grid item xs={matchSM ? 12 : isNative ? 4 : 5}>
                    <Grid item xs={12}
                          container
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={matchSM ? 0 : 2}>
                      <Grid item xs={isNative ? 12 : matchSM ? 12 : 6} >
                        <Typography style={{marginTop:matchSM ? 20 : 0, marginBottom:matchSM ? 20 : 0}}>{currentLocale.suggestions ?? 'Suggestions'}</Typography>
                        {isLoadingAI ?
                           <Box
                              style={{height:isNative ? '25vh' : matchSM ? 200 : 400, position:'relative'}}>
                             <LinearProgress color={'secondary'} style={{width:'80%', top:'50%', left:'10%', position:'absolute'}} />
                           </Box>
                        :
                           <MarkdownEditor.Markdown
                              style={{height:isNative ? '25vh' : matchSM ? 200 : 400, overflowY:'auto'}}
                              source={autocompleteSummary}
                              warpperElement={{"data-color-mode": darkMode ? "dark" : "light"}}
                           />
                        }
                      </Grid>
                      <Grid item xs={isNative ? 12 : matchSM ? 12 : 6}>
                        <Typography style={{marginTop:matchSM ? 20 : 0, marginBottom:matchSM ? 20 : 0}}>{currentLocale.grammar_check ?? 'Grammar Check'}</Typography>
                        {isLoadingAI ?
                           <Box
                              style={{height:isNative ? '25vh' : matchSM ? 200 : 400, position:'relative'}}>
                             <LinearProgress color={'secondary'} style={{width:'80%', top:'50%', left:'10%', position:'absolute'}} />
                           </Box>
                           :
                        <MarkdownEditor.Markdown
                           style={{height:isNative ? '25vh' : matchSM ? 200 : 400, overflowY:'auto'}}
                           source={grammerSummary}
                           warpperElement={{"data-color-mode": darkMode ? "dark" : "light"}}
                        />
                        }
                      </Grid>
                      <Grid item xs={12}>
                        <Typography style={{marginTop:matchSM ? 20 : 0, marginBottom:matchSM ? 20 : 0}}>{currentLocale.context ?? 'Context'}</Typography>
                        {isLoadingAI ?
                           <Box
                              style={{height:isNative ? '25vh' : matchSM ? 200 : 400, position:'relative'}}>
                             <LinearProgress color={'secondary'} style={{width:'80%', top:'50%', left:'10%', position:'absolute'}} />
                           </Box>
                           :
                        <MarkdownEditor.Markdown
                           style={{height:isNative ? '25vh' : matchSM ? 300 : 400, overflowY:'auto'}}
                           source={contextSummary}
                           warpperElement={{"data-color-mode": darkMode ? "dark" : "light"}}
                        />}
                      </Grid>
                    </Grid>
                  </Grid>
                  </Grid>
    </Grid>
  );
}

export default AIWriter;
