import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import {Card, CardContent, Divider, Grid, useMediaQuery, useTheme} from '@material-ui/core';
import BioBox from './components/bioBox';
import TitleBox from "./components/TitleBox";
import {SearchContext, SearchContextProvider, ThemeContext} from "./lib/contexts";
import IndexTicker from "./components/IndexTicker";
import DarkModeToggle from "./components/DarkModeToggle";
import { QueryClient, QueryClientProvider } from 'react-query';
import { LightTheme, DarkTheme } from './lib/themes';
import RandomQuote from "./components/RandomQuote";
import SponsoredBox from "./components/SponsoredBox";
import AIWriter from "./components/AIWriter/AIWriter";
import {getUrlParam} from "./lib/constants/helpers";

const useStyles = makeStyles({
   native: {
      margin:0,
      padding:0,
      width: '100vw',
      height: '100vh',
      '& .rootGrid':{
         margin:'0px auto 0px',
         padding:0,
      }
   },
   root: {
      padding:'2.5%',
      width: '100%'
   },
   smallCard: {
      minWidth: 200
   },
   bigCard: {
      margin:'auto auto auto',
      width:'100%',
   },
   nativeCard: {
      margin:'auto auto auto',
      width:'100%',
      height:'100vh'
   }
});

const App = () => {
   const classes = useStyles();
   const theme = useTheme();
   const [isNative, setIsNative] = useState( false);
   
   const matchSM = useMediaQuery(theme.breakpoints.only('xs'));
   //const matchMDD = useMediaQuery(theme.breakpoints.only('md'));
   //const matchMD = useMediaQuery(theme.breakpoints.up('md'));
   //const matchLG = useMediaQuery(theme.breakpoints.only('lg'));
   //const matchXL = useMediaQuery(theme.breakpoints.up('xl'));
   
   
   useEffect(() => {
      if (getUrlParam('native')) {
         setIsNative(true)
      }
   },[]);
   
   return (
       <SearchContextProvider>
       <Grid
          className={isNative ? classes.native : classes.root +" mobile"}
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          spacing={matchSM ? 1 : 4}
       >
          {!isNative && <Grid item xs={!matchSM ? 2 : 12}>
             <TitleBox/>
             {!matchSM && <BioBox/>}
             {!matchSM && <SponsoredBox/>}
          </Grid>}
          <Grid item xs={isNative ? 12 : !matchSM ? 10 : 12} className={'rootGrid'}>
       <Card className={isNative ? matchSM ? classes.bigCard : classes.nativeCard : classes.bigCard}>
          <CardContent>
          <Grid
             justifyContent="space-between"
             container
             item
             xs={12}
          spacing={0}>
             <Grid item xs={8}>
                <IndexTicker/></Grid>
             <Grid item xs={4}>
                <DarkModeToggle/></Grid>
          </Grid>
          <Divider style={{marginTop:5,marginBottom:20}}/>
          <AIWriter isNative={isNative}/>
          {!isNative && matchSM && <BioBox/>}
          {!isNative && matchSM && <SponsoredBox/>}
          </CardContent>
          </Card>
             {/*<RandomQuote/>*/}
          </Grid>
          </Grid>
       </SearchContextProvider>
  );
}
const ThemeContainer = () => {
   const [darkMode, setDarkMode] = useState(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
   const toggleTheme = useCallback(() => {
      setDarkMode(!darkMode);
   },[darkMode]);
   const chosenTheme = useMemo(()=>darkMode ? DarkTheme() : LightTheme(),[darkMode]);
   return (
      <ThemeContext.Provider value={{darkMode,toggleTheme}}>
      <ThemeProvider theme={chosenTheme}>
            <App />
      </ThemeProvider>
      </ThemeContext.Provider>
   );
};

const AppContainer = () => {
   const queryClient = new QueryClient();
   return (
      <QueryClientProvider client={queryClient}>
               <ThemeContainer />
      </QueryClientProvider>
   );
};

export default AppContainer;
