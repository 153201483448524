import React, {useCallback, useContext, useEffect, useState} from 'react';
import {CardContent, makeStyles, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import {SearchContext, ThemeContext} from "../../lib/contexts";
const useStyles = makeStyles({
   smallCard: {
      minWidth: 200,
      cursor:'pointer'
   },
   smallCardMobile: {
      minWidth: 200,
      textAlign:'center',
      cursor:'pointer',
      fontSize: '80%',
   },
   bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
   },
   title: {
      fontSize: 14,
   },
   tagline: {
      marginBottom: -10
   },
   taglineMobile: {
      fontSize: 14,
   },
   version : {
      fontSize:13,
      fontWeight:'bold',
      color:'rgba(0, 0, 0, 0.87)'
   },
   versionDark : {
      fontSize:13,
      fontWeight:'bold',
      color: '#fafafa',
   },
   loadingBar: {
      width:'80%',
      margin:'40px auto 30px'
   },
});
const IndexTicker = () => {
   const classes = useStyles();
   const theme = useTheme();
   const matchSM = useMediaQuery(theme.breakpoints.only('xs'));
   
   const {
      state: { currentLocale },
   } = useContext(SearchContext);

   return(
      <CardContent>
         <Typography className={matchSM ? classes.taglineMobile : classes.tagline} style={{position:"relative",left:0}}>
            {currentLocale.pro_tip ?? 'Pro-Tip: For the best results, use Markdown formatting when writing.'}
         </Typography>
      </CardContent>
   )
};
export default IndexTicker;
